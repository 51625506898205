import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PrintIcon from "@material-ui/icons/Print";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import moment from "moment";
//import momentDurationFormatSetup from 'moment-duration-format';
import "moment-duration-format";

import api from "../api";

export default function ListadeCargas() {
  const [cargas, setCargas] = useState([]); //recebe do banco de dados
  const [search, setSearch] = useState(""); //set de busca
  const classes = useRowStyles();

  // FILTAR USUARIOS NA TABELA
  const filtraprincipal = cargas.filter((item) => {
    return item.cviagens.includes(search.toLowerCase());
  });

  //busca dados api
  useEffect(() => {
    api
      .get("painelProcessamentoDocenviadoAutomatico")
      .then((res) => {
        let carg = res.data;
        //funcao para ordenar a lista por hora
        const itens = carg.sort(function (a, b) {
          var c = new Date(a.datastatusdoc).getTime();
          var d = new Date(b.datastatusdoc).getTime();
          return d - c; //data mais antiga para mais nova
        });

        setCargas(itens);
      })
      .catch(() => alert("erro ao carregar"));
  }, []);

  function FiltroCargaPDF(id) {
    let filtra = cargas.filter((x) => x.id == id);

    function PDFdadosNotas() {
      let htmlString =
        "<table><thead><tr><th>Numero Nota</th><th>Data Emissao</th><th>Loja</th></tr></thead><tbody>";

      // Segundo map
      filtra.forEach((i) => {
        i.cviagens.forEach((j) => {
          j.fk_notas.forEach((nota) => {
            htmlString += `<tr style='height: 10px;' >`;
            htmlString += `<td style='font-size: 12px' >${nota.nr_nota}</td>`; // Adicione o estilo diretamente aqui
            htmlString += `<td style='font-size: 12px'>${new Date(
              nota.dt_emissao
            ).toLocaleString()}</td>`;
            htmlString += `<td style='font-size: 12px' >${j.fkdestino.razao}</td>`; // Adicione o estilo diretamente aqui
            htmlString += "</tr>";
          });
        });
      });
      htmlString += "</tbody></table>";
      return htmlString;
    }

    function PDFresumoDados() {
      let htmlString =
        "<table><thead><tr><th>Lojas</th><th>Perfil</th><th>Qtd Nota</th></tr></thead><tbody>";
      let QtdEntrega = [];

      let totalnf = [];

      // total de entrega
      filtra.forEach((i) => {
        i.cviagens.forEach((j) => {
          QtdEntrega.push(j.fkdestino.id);
        });
      });

      // lojas
      filtra.forEach((i) => {
        i.cviagens.forEach((j) => {
          htmlString += `<tr style='height: 10px;' >`;
          htmlString += `<td style='font-size: 12px' >Lojas ${j.fkdestino.lojas}</td>`;
          let QtdNotas = [];
          j.fk_notas.forEach((nota) => {
            QtdNotas.push(nota.nr_nota);
            totalnf.push(nota.nr_nota);
          });
          htmlString += `<td style='font-size: 12px' >${j.perfil}</td>`;
          htmlString += `<td style='font-size: 12px' >${QtdNotas.length}</td>`;
        });
      });

      htmlString += "</tr>";

      htmlString += "</tbody></table>";
      htmlString += `<p style='font-size: 14px' ><strong>Total Entrega :</strong> ${QtdEntrega.length}</p>`;
      htmlString += `<p style='font-size: 14px' ><strong>Total Nota :</strong> ${totalnf.length}</p>`;

      return htmlString;
    }

    function PDFdadosMotorista() {
      let htmlString = "<tr>";

      // Segundo map
      filtra.map((valor) => {
        htmlString += "<tr>";
        htmlString += `<td ><strong>Codigo:&nbsp </strong>${valor.fkmotorista.codigo}</td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
        htmlString += `<td ><strong>Motorista:&nbsp </strong>${valor.fkmotorista.nome}</td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
        htmlString += `<td ><strong>Tipo:&nbsp </strong>${valor.fkmotorista.tipo}</td > `;
        htmlString += "</tr>";
      });

      return htmlString;
    }

    function PDFdadosVeiculo() {
      let htmlString = "<tr>";

      // Segundo map
      filtra.map((valor) => {
        htmlString += "<tr>";
        htmlString += `<td ><strong>Veiculo Trator:&nbsp</strong>${valor.fkveiculotrator.placa}</td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
        htmlString += `<td ><strong>Unidade de Carga:&nbsp</strong>${valor.fkveiculo.nfrota}</td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;

        htmlString += `<td ><strong>Placa:&nbsp </strong>${valor.fkveiculo.placa}</td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
        htmlString += `<td ><strong>Tipo:&nbsp </strong>${valor.fkveiculo.tipoveiculo}</td > `;
        htmlString += "</tr>";
      });

      return htmlString;
    }

    const DadosMotorista = PDFdadosMotorista();
    const DadosVeiculo = PDFdadosVeiculo();
    const DadosNotas = PDFdadosNotas();
    const ResumoDados = PDFresumoDados();

    const idcarga = filtra.map((x) => x.ncarga);

    const win = window.open("", "", "width=800,height=600");
    win.document.write(`
      <html>
      <head>
        <title>Cargas Koch</title>
        <style>
          table {
            border-collapse: collapse;
            width: 100%;
          }
          
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
          }
          
          th {
            background-color: #dddddd;
          }
          h2 {
            background-color: #194D33;
          }
          .dadosMotorista {
            margin-bottom: 8px;
            
          }
          .dadosVeiculo {
            margin-bottom: 55px;
            
          }
          .dadosNotas {
           
            
          }
          .cabecalho{
            display: flex;
            justify-content: space-between;
          
          }
          
        </style>
      </head>
      <body>
      <div class="cabecalho">

      <h1>Cargas Koch</h1>
      <h1>Nº ${idcarga}</h1>
      </div>
     
      <div  class="dadosMotorista">
      ${DadosMotorista}
      </div>
     
      <div  class="dadosVeiculo">
      ${DadosVeiculo}
      </div>

      <div  class="dadosVeiculo">
      ${ResumoDados}
      </div>
     
    
      <div  class="dadosNotas">
      ${DadosNotas}
      </div>
      </body>
      </html>
    `);

    win.document.close();
    win.print();

    return filtra;
  }

  //EXIBE DADOS DA TABELA
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [opennt, setOpennt] = useState(false);
    const [openedDeliveryId, setOpenedDeliveryId] = useState(null);

    //FORMATAR DATA VISUALIZADO
    let visualizado = row.datastatusprocessamento;
    let hors = new Date(visualizado);
    let hora = hors.getUTCHours();
    let corrhora = hora;
    let [date, month, year] = new Date(visualizado)
      .toLocaleDateString("pt-BR")
      .split("/");
    let formatadevisualizado =
      date +
      "/" +
      month +
      "/" +
      year +
      " " +
      corrhora +
      ":" +
      hors.getUTCMinutes();

    //FORMATAR DATA COM NF
    let nota = row.datastatusnf;
    let horsnota = new Date(nota);
    let horanota = horsnota.getUTCHours();
    let corrhoranota = horanota;
    let [datenota, monthnota, yearnota] = new Date(nota)
      .toLocaleDateString("pt-BR")
      .split("/");
    let formatadenota =
      datenota +
      "/" +
      monthnota +
      "/" +
      yearnota +
      " " +
      corrhoranota +
      ":" +
      horsnota.getUTCMinutes();

    //FORMATAR DATA DOCUMENTO ENVIADO
    let documento = row.datastatusdoc;
    let dochoras = new Date(documento);
    let horadoc = dochoras.getUTCHours();
    let [datedoc, monthdoc, yeardoc] = new Date(documento)
      .toLocaleDateString("pt-BR")
      .split("/");
    let formatadedoc =
      datedoc +
      "/" +
      monthdoc +
      "/" +
      yeardoc +
      " " +
      horadoc +
      ":" +
      dochoras.getUTCMinutes();

    //FORMATAR DATA DOCUMENTO SOLICITADO
    let documentosolicitado = row.datasolicitamdfe;
    let docshoras = new Date(documentosolicitado);
    let horadocs = docshoras.getUTCHours();
    let [datedocs, monthdocs, yeardocs] = new Date(documentosolicitado)
      .toLocaleDateString("pt-BR")
      .split("/");
    let formatadedocs =
      datedocs +
      "/" +
      monthdocs +
      "/" +
      yeardocs +
      " " +
      horadocs +
      ":" +
      docshoras.getUTCMinutes();

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            className={classes.txtOperacaotabela}
          >
            {row.id}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.fkmotorista.nome}
          </TableCell>
          <TableCell align="center">{row.fkmotorista.tipo}</TableCell>
          <TableCell align="center">{row.fkveiculotrator.placa}</TableCell>
          <TableCell align="center"> {row.fkveiculo.placa}</TableCell>
          {(() => {
            // let origem = row.cviagens.fkorigem.descricao;
            let origem = [
              ...new Set(row.cviagens.map((x) => x.fkorigem.descricao)),
            ];

            return (
              <TableCell
                component="th"
                scope="row"
                className={classes.txtOperacaotabela}
              >
                {origem}
              </TableCell>
            );
          })()}
          <TableCell align="center">
            <Typography
              style={{
                fontFamily: "inherit",
                fontWeight: "bold",
                color: "#40bf80",
              }}
            >
              {row.ncarga}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Button
              onClick={() => {
                FiltroCargaPDF(row.id);
              }}
            >
              <PrintIcon color="primary" />
            </Button>
          </TableCell>
          <TableCell align="center">{formatadedocs}</TableCell>
          <TableCell align="center">{formatadevisualizado}</TableCell>
          <TableCell align="center">{formatadenota}</TableCell>
          <TableCell align="center">{formatadedoc}</TableCell>
        </TableRow>

        {/*EXIBI DETALHES DA CARGA */}
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Detalhes
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Lojas</TableCell>
                      <TableCell>Perfil</TableCell>
                      <TableCell align="center">Qtd P</TableCell>
                      <TableCell align="center">Total Nota</TableCell>
                      <TableCell align="center">Cidade</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* segundo map */}
                    {row.cviagens.map((valor) => (
                      <TableRow>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              setOpennt(!opennt);
                              setOpenedDeliveryId(valor.id);
                            }}
                          >
                            {opennt ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {valor.fkdestino.lojas}
                        </TableCell>

                        <TableCell> {valor.perfil}</TableCell>
                        <TableCell align="center">{valor.qtdpallet}</TableCell>
                        <TableCell align="center">
                          <Typography>{valor.fk_notas.length}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          {valor.fkdestino.municipio}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>

        {/*EXIBI DETALHES DA NOTAS */}
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={opennt} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Notas
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Numero Nota</TableCell>
                      <TableCell>Data Emissao</TableCell>
                      <TableCell>Loja</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* segundo map */}

                    {row.cviagens.map((valor) =>
                      valor.fk_notas.map((nota) => {
                        let identrega = openedDeliveryId;

                        if (nota.fk_entregas == identrega) {
                          return (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {nota.nr_nota}
                              </TableCell>
                              <TableCell>
                                {(() => {
                                  const format1 = "DD/MM/YYYY HH:mm:ss";
                                  let horseteste = new Date(nota.dt_emissao);

                                  let formatarentradacd = moment
                                    .utc(horseteste)
                                    .format(format1);

                                  return formatarentradacd;
                                })()}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {valor.fkdestino.lojas}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell />
            <TableCell className={classes.txtheard}>ID carga</TableCell>
            <TableCell className={classes.txtheard}>Motoristas</TableCell>
            <TableCell className={classes.txtheard} align="center">
              Perfil Mt
            </TableCell>
            <TableCell className={classes.txtheard} align="center">
              Placa Cavalo
            </TableCell>
            <TableCell className={classes.txtheard} align="center">
              Placa Carreta
            </TableCell>
            <TableCell align="center" className={classes.txtheard}>
              Operacao
            </TableCell>
            <TableCell className={classes.txtheard} align="center">
              Numero Carga
            </TableCell>
            <TableCell className={classes.txtheard} align="center">
              Opcoes
            </TableCell>
            <TableCell className={classes.txtheard} align="center">
              Dt Doc Solicitado
            </TableCell>
            <TableCell className={classes.txtheard} align="center">
              Dt Visualizado
            </TableCell>
            <TableCell className={classes.txtheard} align="center">
              Dt NF
            </TableCell>

            <TableCell className={classes.txtheard} align="center">
              Dt Doc Enviado{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cargas.map((row) => (
            <Row row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
//styles
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  head: {
    backgroundColor: "#FFFFFF",
  },
  txtheard: {
    fontWeight: "bold",
    fontSize: 13,
  },
  txtOperacaotabela: {
    fontSize: 16,
    color: "#bf4040",
    fontFamily: "inherit",
    fontWeight: "bold",
  },
});
