import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import {
  GiGrapes,
  GiCube,
  GiSlicedBread,
  GiTruck,
  GiSatelliteCommunication,
} from "react-icons/gi";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import { FaTemperatureLow } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import Mapa from "./MapaConsulta";

//utils
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import "../style/style_pag.css";

import Progress_bar from "./Progress_bar";

import api from "../api";
import axios from "axios";
import { ApiTracerVeiculos } from "../service/authtracer";

const useStyles = makeStyles({
  title: {
    fontSize: 14,
    marginTop: -9,
    //  marginLeft: 40,
    color: "#ffff",
    alignItems: "center",
  },
  cabecalhos: {
    color: "black",
    fontSize: 12,
    fontWeight: 750,
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#b71c1c",
    color: "#b71c1c",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function Dashtabelaveiculotransito({ datafiltra, operacoes }) {
  const classes = useStyles();
  const [pontoreferencia, setPontoreferencia] = useState();
  const [veiculostracer, setVeiculostracer] = useState();
  const [veiculostransito, setVeiculostransito] = useState(1);
  const [veiculosLojas, setVeiculosLojas] = useState();
  const [posicaomapa, setPosicaomapa] = useState([]);

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (i) => {
    setOpen(true);
    setPosicaomapa(i);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
      } else {
        const diff = Math.random() * 8;

        setProgress(progress + diff);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  /* 
GEROU UM CUSTO MUITO AUTO NA API DO GOOGLE VERIFICAR OUTRA FORMA DE AJUSTAR ISSO
 const fetchLocationData = async (lat, lon) => {
    const apiKey = "pk.d721bde2ad3ac8bfe17cd283a341e2d9"; // Substitua pela sua chave de API
    const url = `https://us1.locationiq.com/v1/reverse.php?key=${apiKey}&lat=${lat}&lon=${lon}&format=json`;

    try {
      const response = await axios.get(url);
      const data = response.data;
      const city =
        data.address.city || data.address.town || data.address.village;
      return {
        idponto: data.display_name,
        cidade: city,
      };
    } catch (error) {
      console.error("Erro ao buscar o endereço:", error);
      return {
        idponto: "Erro ao buscar localização",
        cidade: "Erro ao buscar cidade",
      };
    }
  };
  
  
useEffect(() => {
    const interval = setInterval(() => {
      new Promise((resolve, reject) => {
        if (veiculostransito !== 1) {
          let idponto = pontoreferencia;
          let placas = veiculostracer;
          let cargas = veiculostransito;
          let placasnoraio = [];
          let placasfora = [];
          let Op = Number(operacoes);
          let promises = [];
          placas.forEach((veiculo) => {
            let dentro = false;
            idponto.forEach((ponto) => {
              let distancia = geolocalizacao(
                veiculo[1],
                veiculo[2],
                ponto.latitude,
                ponto.longitude
              );
              if (distancia <= ponto.cerca) {
                dentro = true;
                placasnoraio.push({
                  idponto: ponto.id,
                  cidade: ponto.cidade,
                  ponto: ponto.pontos,
                  placa: veiculo[0],
                  temperature: veiculo.sensor
                    ? veiculo.sensor.temperature
                    : "0",
                  velocidade: veiculo[4],
                  posicao: [veiculo[1], veiculo[2]],
              israio: true,
                });
              }
            });
            if (!dentro) {
              let promise = fetchLocationData(veiculo[1], veiculo[2])
                .then((location) => {
                  placasfora.push({
                    idponto: "Buscando localizacao....",
            cidade: promise.cidade,
            ponto: `${veiculo[1]},${veiculo[2]}`,
            placa: veiculo[0],
            temperature: veiculo.sensor ? veiculo.sensor.temperature : "0",
            velocidade: veiculo[4],
            posicao: [veiculo[1], veiculo[2]],
            israio: false,
                  });
                })
                .catch((error) => {
                  console.error("Error fetching address:", error);
                  placasfora.push({
                    idponto: "Buscando localizacao....",
            cidade: "Pesquisando Cidade...",
            ponto: `${veiculo[1]},${veiculo[2]}`,
            placa: veiculo[0],
            temperature: veiculo.sensor ? veiculo.sensor.temperature : "0",
            velocidade: veiculo[4],
            posicao: [veiculo[1], veiculo[2]],
            israio: false,
                  });
                });
              promises.push(promise);
            }
          });
          Promise.all(promises).then(() => {
            let placastransito = [...placasnoraio, ...placasfora];
            let novoArray = [];
            placastransito.map((item) =>
              cargas?.map((x) => {
                if (x.fkplaca.placa == item.placa) {
                  novoArray.push(Object.assign(item, x));
                }
              })
            );
            const filteredViagens = novoArray?.filter((v) => {
              const origemOperacoesIds = v.fkviagens.cviagens?.flatMap((c) =>
                c.fkorigem.Cad_operacoes?.map((o) => o.id)
              );
              return origemOperacoesIds.some((id) => id === Op);
            });
            setDados(filteredViagens); // Atualizando o estado
            resolve(filteredViagens);
          });
        } else {
          console.log("teste");
          resolve([]);
        }
      });
    }, 4000);

    return () => clearInterval(interval);
  }, [veiculostransito, pontoreferencia, veiculostracer, operacoes, setDados]);


*/

  function geolocalizacao(lat1, lon1, lat2, lon2) {
    // distance between latitudes
    // and longitudes
    let dLat = ((lat2 - lat1) * Math.PI) / 180.0;
    let dLon = ((lon2 - lon1) * Math.PI) / 180.0;

    // converte em radiano
    lat1 = (lat1 * Math.PI) / 180.0;
    lat2 = (lat2 * Math.PI) / 180.0;

    // aplica a formula
    let a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    let rad = 6371000; //circoferencia aproximada da terra, se querer passar para km cortar tres 0.
    let c = 2 * Math.asin(Math.sqrt(a));
    return rad * c;
  }

  //veiculos em lojas
  useEffect(() => {
    api
      .get("/dashFrotaveiculosLojaControllers")
      .then((res) => {
        let carg = res.data;
        let nulo = null;
        let dados = carg.filter(
          (item) => item.fkstatusentregas.fkviagens !== nulo
        );
        const placas = dados.map(
          (item) => item.fkstatusentregas.fkviagens.fkveiculo.placa
        );
        setVeiculosLojas(placas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  //veiculos em lojas
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/dashFrotaveiculosLojaControllers")
        .then((res) => {
          let carg = res.data;
          let nulo = null;

          let dados = carg.filter(
            (item) => item.fkstatusentregas.fkviagens !== nulo
          );
          const placas = dados.map(
            (item) => item.fkstatusentregas.fkviagens.fkveiculo.placa
          );
          setVeiculosLojas(placas);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //tracer
  useEffect(() => {
    axios
      .get(ApiTracerVeiculos)
      .then((res) => {
        let carg = res.data[0].items;

        let dados = carg.map((item) => [
          item.name,
          item.lat,
          item.lng,
          item.temperature,
          item.speed,
        ]);

        setVeiculostracer(dados);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(ApiTracerVeiculos)
        .then((res) => {
          let carg = res.data[0].items;

          let dados = carg.map((item) => [
            item.name,
            item.lat,
            item.lng,
            item.temperature,
            item.speed,
          ]);

          setVeiculostracer(dados);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  //ponto referencia
  useEffect(() => {
    api
      .get("/pontoreferencias")
      .then((res) => {
        let ponto = res.data;

        setPontoreferencia(ponto);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/pontoreferencias")
        .then((res) => {
          let ponto = res.data;
          setPontoreferencia(ponto);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  //veiculos em transito
  useEffect(() => {
    api
      .get("/dashFrotaveiculotransito")
      .then((res) => {
        let dados = res.data;
        // console.log('dashFrotaveiculotransito', dados);
        setVeiculostransito(dados);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/dashFrotaveiculotransito")
        .then((res) => {
          let dados = res.data;
          // console.log('dashFrotaveiculotransito', dados);
          setVeiculostransito(dados);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  /*   */
  function metodoPrincipal() {
    if (veiculostransito !== 1) {
      let idponto = pontoreferencia;
      let placas = veiculostracer;
      let cargas = veiculostransito;
      let veiculosloja = veiculosLojas;
      //let placastransito = [];
      let placasnoraio = [];
      let placasfora = [];
      let Op = Number(operacoes);

      let ultimoPonto = [];

      placas?.forEach((veiculo) => {
        let dentro = false;
        idponto?.forEach((ponto) => {
          let distancia = geolocalizacao(
            veiculo[1],
            veiculo[2],
            ponto.latitude,
            ponto.longitude
          );
          if (distancia <= ponto.cerca) {
            dentro = true;
            placasnoraio.push({
              idponto: ponto.id,
              cidade: ponto.cidade,
              ponto: ponto.pontos,
              placa: veiculo[0],
              temperature: veiculo.sensor ? veiculo.sensor.temperature : "0",
              velocidade: veiculo[4],
              posicao: [veiculo[1], veiculo[2]],
              israio: true,
            });
          }
        });
        if (!dentro) {
          placasfora.push({
            idponto: "Buscando localizacao....",
            cidade: "Pesquisando Cidade...",
            ponto: `${veiculo[1]},${veiculo[2]}`,
            placa: veiculo[0],
            temperature: veiculo.sensor ? veiculo.sensor.temperature : "0",
            velocidade: veiculo[4],
            posicao: [veiculo[1], veiculo[2]],
            israio: false,
          });
        }
      });

      let placastransito = [...placasnoraio, ...placasfora];
      let novoArray = [];

      //placas em transito
      placastransito.map((item) =>
        cargas?.map((x) => {
          if (x.fkplaca.placa == item.placa) {
            novoArray.push(Object.assign(item, x));
          }
        })
      );

      // Filtrando as viagens com base nos IDs de operações do usuário
      const filteredViagens = novoArray?.filter((v) => {
        // Obtendo todos os IDs de operações associadas à origem da viagem
        const origemOperacoesIds = v.fkviagens.cviagens?.flatMap((c) =>
          c.fkorigem.Cad_operacoes?.map((o) => o.id)
        );

        // Verificando se algum dos IDs de operações da origem está presente nos IDs de operações do usuário
        return origemOperacoesIds.some((id) => id === Op);
      });

      //verifica se tem veiculos em loja e apresenta só os que estao fora delas
      const result = filteredViagens?.filter(
        (item) => !veiculosloja.includes(item.placa)
      );
      return result;
    } else {
      console.log("teste");
    }
  }

  let dados = metodoPrincipal();

  return (
    <Card
      style={{
        //  background: "#191E4D",
        justifyItems: "flex-start",
        justifyContent: "space-around",
        border: " 2px solid  #F4F6F9",
        borderRadius: 11,
      }}
    >
      <div>
        <Typography
          style={{
            background: "#5C677D",
            justifyItems: "flex-start",
            justifyContent: "space-around",
            color: "white",
            font: "monospace",
            height: 35,
            fontSize: 20,
          }}
        >
          Veiculos em Transito
        </Typography>
      </div>
      {(() => {
        if (!dados) {
          return (
            <Box
              sx={{
                width: "100%",
                height: 50,
              }}
            >
              <Progress_bar bgcolor="#191E4D" progress={progress} height={4} />
            </Box>
          );
        }
      })()}
      <TableContainer className={classes.root} sx={{ maxHeight: 550 }}>
        <Table
          aria-label="a dense table"
          sx={{
            //  width: '100%',

            bgcolor: "background.paper",
            //  position: 'relative',
            overflow: "auto",
            maxHeight: 50,
            // minHeight: 550,
            minWidth: 140,
          }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography className={classes.cabecalhos}>Cavalo</Typography>
              </TableCell>
              <TableCell align="center">
                <Tooltip title="Unidade de Carga">
                  <Typography className={classes.cabecalhos}>U.C</Typography>
                </Tooltip>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>Lojas</Typography>
              </TableCell>

              <TableCell align="center">
                <Typography className={classes.cabecalhos}>Perfil</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>T</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>Cidade</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>Local</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dados?.map((item) => (
              <TableRow
                style={{
                  maxHeight: 10,
                  //  backgroundColor: 'red',
                }}
              >
                <TableCell component="th" scope="row">
                  {item.velocidade <= 0 ? (
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      variant="dot"
                    >
                      <Typography
                        className="textoareaplaca"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        {item?.fkviagens?.fkveiculotrator.placa}
                      </Typography>
                    </StyledBadge>
                  ) : (
                    <Typography
                      className="textoareaplaca"
                      style={{
                        fontSize: 15,
                      }}
                    >
                      {item?.fkviagens?.fkveiculotrator.placa}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="center">
                  <Typography
                    className="textoareanfrota"
                    style={{ fontSize: 15, marginLeft: -25 }}
                  >
                    {item?.fkplaca?.nfrota}
                  </Typography>
                </TableCell>
                <div>
                  {item.fkviagens?.cviagens?.map((x) => (
                    <TableCell align="center">
                      {(() => {
                        let status = x.statusentrega;

                        if (status == "Pendente") {
                          return (
                            <div
                              style={{
                                paddingTop: 9,
                              }}
                            >
                              <Typography
                                className="textoarealoja"
                                style={{
                                  fontSize: 14,
                                  marginLeft: -25,
                                }}
                              >
                                {x.fkdestino.lojas}
                              </Typography>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              style={{
                                paddingTop: 9,
                              }}
                            >
                              <Typography
                                className="textoarealojaentregue"
                                style={{
                                  fontSize: 14,
                                  marginLeft: -25,
                                }}
                              >
                                {x.fkdestino.lojas}
                              </Typography>
                            </div>
                          );
                        }
                      })()}
                    </TableCell>
                  ))}
                </div>
                <TableCell align="center">
                  {(() => {
                    let perfil = item.fkviagens?.cviagens?.map((x) => x.perfil);
                    var filtra = perfil?.filter(
                      (x, i) => perfil.indexOf(x) === i
                    );

                    if (
                      perfil?.includes("Normal") &&
                      perfil?.includes("Horti") &&
                      !perfil?.includes("Frios")
                    ) {
                      return (
                        <Tooltip title="Carga Mista">
                          <Typography>
                            <GiCube
                              style={{
                                fontSize: 18,
                                color: "#fcb900",
                                marginLeft: -10,
                              }}
                            />

                            <GiGrapes
                              style={{
                                fontSize: 18,
                                color: "#194d33",
                                //   marginLeft: -10,
                              }}
                            />
                          </Typography>
                        </Tooltip>
                      );
                    }
                    //PERFIL FRIOS E HORTI E NORMAL
                    else if (
                      perfil.includes("Frios") &&
                      perfil.includes("Horti") &&
                      perfil.includes("Normal")
                    ) {
                      return (
                        <Tooltip title="Carga Mista">
                          <Typography>
                            <GiCube
                              style={{
                                fontSize: 18,
                                color: "#fcb900",
                                //  marginLeft: -10,
                              }}
                            />
                            <AcUnitIcon
                              style={{
                                fontSize: 18,
                                color: "#0d47a1",
                                // marginLeft: -10,
                              }}
                            />

                            <GiGrapes
                              style={{
                                fontSize: 18,
                                color: "#194d33",
                                //   marginLeft: -10,
                              }}
                            />
                          </Typography>
                        </Tooltip>
                      );
                    }
                    //PERFIL FRIOS E HORTI E NORMAL
                    else if (
                      perfil.includes("Frios") &&
                      !perfil.includes("Horti") &&
                      perfil.includes("Normal")
                    ) {
                      return (
                        <Tooltip title="Carga Mista">
                          <Typography>
                            <GiCube
                              style={{
                                fontSize: 18,
                                color: "#fcb900",
                                //  marginLeft: -10,
                              }}
                            />

                            <AcUnitIcon
                              style={{
                                fontSize: 18,
                                color: "#0d47a1",
                                // marginLeft: -10,
                              }}
                            />
                          </Typography>
                        </Tooltip>
                      );
                    }
                    //PERFIL FRIOS E HORTI
                    else if (
                      perfil.includes("Frios") &&
                      perfil.includes("Horti") &&
                      !perfil.includes("Normal")
                    ) {
                      return (
                        <Tooltip title="Carga Mista">
                          <Typography>
                            <AcUnitIcon
                              style={{
                                fontSize: 18,
                                color: "#0d47a1",
                                // marginLeft: -10,
                              }}
                            />

                            <GiGrapes
                              style={{
                                fontSize: 18,
                                color: "#194d33",
                                //   marginLeft: -10,
                              }}
                            />
                          </Typography>
                        </Tooltip>
                      );
                    } else if (perfil.includes("Horti")) {
                      return (
                        <Tooltip title="Carga Horti">
                          <GiGrapes
                            style={{
                              fontSize: 22,
                              color: "#194d33",
                              //marginLeft: -10,
                            }}
                          />
                        </Tooltip>
                      );
                    } else if (perfil.includes("Normal")) {
                      return (
                        <Tooltip title="Carga Ceca">
                          <GiCube
                            style={{
                              fontSize: 22,
                              color: "#fcb900",
                              //marginLeft: -10,
                            }}
                          />
                        </Tooltip>
                      );
                    } else if (perfil.includes("Frios")) {
                      return (
                        <Tooltip title="Carga Frios">
                          <AcUnitIcon
                            style={{
                              fontSize: 22,
                              color: "#0d47a1",
                              // marginLeft: -10,
                            }}
                          />
                        </Tooltip>
                      );
                    } else if (perfil.includes("Panificacao Seca")) {
                      return (
                        <Tooltip title="Panificacao Seca">
                          <GiSlicedBread
                            style={{
                              fontSize: 22,
                              color: "#fcdc00",
                              // marginLeft: -10,
                            }}
                          />
                        </Tooltip>
                      );
                    } else if (perfil.includes("Panificacao Frios")) {
                      return (
                        <Tooltip title="Panificacao Frios">
                          <GiSlicedBread
                            style={{
                              fontSize: 22,
                              color: "#B4C4BC",
                              // marginLeft: -10,
                            }}
                          />
                        </Tooltip>
                      );
                    } else {
                      return console.log("tes");
                    }
                  })()}
                </TableCell>
                <TableCell align="center">
                  <Typography
                    style={{
                      fontSize: 14,
                    }}
                  >
                    <FaTemperatureLow
                      style={{
                        fontSize: 10,
                        color: "red",
                      }}
                    />
                    {item.temperature}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    style={{
                      fontSize: 15,
                    }}
                  >
                    <LocationOnIcon
                      style={{
                        fontSize: 15,
                        color: "red",
                      }}
                    />
                    {item.cidade}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    style={{
                      fontSize: 15,
                    }}
                  >
                    <Button onClick={() => handleClickOpen(item.posicao)}>
                      <LocationOnIcon
                        style={{
                          fontSize: 15,
                          color: "red",
                        }}
                      />
                    </Button>
                    <Dialog
                      fullWidth
                      maxWidth="lg"
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        latitude e longitude : {item.posicao}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <Mapa
                            transito={posicaomapa}
                            operacoesMapa={operacoes}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Fechar</Button>
                      </DialogActions>
                    </Dialog>
                    {item.israio === false ? (
                      <div style={{ marginLeft: 70 }}>
                        <GiSatelliteCommunication className="containersatelite" />
                      </div>
                    ) : (
                      item.ponto
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
