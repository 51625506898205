import api from "../api";

//METODO DELETAR VEICULO
export const deleteVeiculo = async (id) => {
  await api.delete(`/veiculos/${id}`);
};

//METODO BUSCAR VEICULO
export const getVeiculo = async () => {
  const response = await api.get(`/veiculos`);
  return response.data;
};

//METODO BUSCAR VEICULO POR  ID
export const getveiculoid = async (id) => {
  const response = await api.get(`veiculos/${id}`);
  return response.data;
};

//METODO UPDATE
export const putVel = async (
  id,
  {
    nfrota,
    placa,
    marca,
    cor,
    status,
    vinculo,
    qtdpallet,
    comprimento,
    tipoveiculo,
    tipocarroceria,
    created_at,
  }
) => {
  const response = await api.put(`veiculos/${id}`, {
    nfrota,
    placa,
    marca,
    cor,
    status,
    vinculo,
    qtdpallet,
    comprimento,
    tipoveiculo,
    tipocarroceria,
    created_at,
  });
  return response.data;
};

//##########################################TABELA MOTORISTA #########################################

//METODO BUSCAR motoristas
export const getMotorista = async () => {
  const response = await api.get(`/motoristas`);
  return response.data;
};
//METODO DELETAR MOTORISTA
export const deleteMotorista = async (id) => {
  await api.delete(`/motoristas/${id}`);
};

//METODO BUSCAR MOTORISTA POR  ID
export const getMotoristaid = async (id) => {
  const response = await api.get(`motoristas/${id}`);
  return response.data;
};

//METODO UPDATE
export const putMot = async (
  id,
  { codigo, nome, cpf, turno, tipo, status, created_at }
) => {
  const response = await api.put(`motoristas/${id}`, {
    codigo,
    nome,
    cpf,
    turno,
    tipo,
    status,
    created_at,
  });
  return response.data;
};

//##########################################TABELA ORIGEM #########################################

//METODO BUSCAR ITENS
export const getOrigem = async () => {
  const response = await api.get(`/origem`);
  return response.data;
};
//METODO DELETAR
export const deleteOrigem = async (id) => {
  await api.delete(`/origem/${id}`);
};

//METODO BUSCAR  POR  ID
export const getOrigemid = async (id) => {
  const response = await api.get(`origem/${id}`);
  return response.data;
};

//METODO UPDATE
export const putOrig = async (
  id,
  {
    codigo,
    razao,
    cnpj,
    descricao,
    rua,
    numero,
    cep,
    municipio,
    uf,
    longitude,
    latitude,
    cerca,
    bairro,
    created_at,
  }
) => {
  const response = await api.put(`origem/${id}`, {
    codigo,
    razao,
    cnpj,
    descricao,
    rua,
    numero,
    cep,
    municipio,
    uf,
    longitude,
    latitude,
    cerca,
    bairro,
    created_at,
  });
  return response.data;
};
//##########################################TABELA Destino #########################################
//METODO BUSCAR ITENS
export const getDestino = async () => {
  const response = await api.get(`/destino`);
  return response.data;
};
//METODO DELETAR
export const deleteDestino = async (id) => {
  await api.delete(`/destino/${id}`);
};

//METODO BUSCAR  POR  ID
export const getDestinoid = async (id) => {
  const response = await api.get(`destino/${id}`);
  return response.data;
};

//METODO UPDATE
export const putDest = async (
  id,
  {
    codigo,
    razao,
    cnpj,
    lojas,
    rua,
    numero,
    cep,
    municipio,
    uf,
    longitude,
    latitude,
    bairro,
    recebimentohrinicial,
    recebimentohrfinal,
    tipoveiculo,
    comprimentoveiculo,
    cerca,

    created_at,
  }
) => {
  const response = await api.put(`destino/${id}`, {
    codigo,
    razao,
    cnpj,
    lojas,
    rua,
    numero,
    cep,
    municipio,
    uf,
    longitude,
    latitude,
    bairro,
    created_at,
    recebimentohrinicial,
    recebimentohrfinal,
    tipoveiculo,
    comprimentoveiculo,
    cerca,
  });
  return response.data;
};

//##########################################TABELA USERS ############################

//METODO BUSCAR ITENS
export const getUsers = async () => {
  const response = await api.get(`/users`);
  return response.data;
};
//METODO DELETAR
export const deleteUsers = async (id) => {
  await api.delete(`/users/${id}`);
};

//METODO UPDATE
export const putUsers = async (
  id,
  { name, cpf, tipo, contato, created_at }
) => {
  const response = await api.put(`users/${id}`, {
    name,
    cpf,
    tipo,
    contato,
    created_at,
  });
  return response.data;
};
//METODO BUSCAR  POR  ID
export const getusersID = async (id) => {
  const response = await api.get(`users/${id}`);
  return response.data;
};

//###################TABELA DE CARGAS

//METODO UPDATE CARGA
export const EditCarga = async (
  id,
  {
    motorista,
    veiculo,
    ncarga,
    idveiculotrator,
    statusentregacarga,
    dataentregacarga,
    statussolicitamdfe,
    datasolicitamdfe,
    statusprocessamento,
    datastatusprocessamento,
    statusnf,
    datastatusnf,
    statusdoc,
    datastatusdoc,
  }
) => {
  const response = await api.put(`viagem/${id}`, {
    motorista,
    veiculo,
    ncarga,
    idveiculotrator,
    statusentregacarga,
    dataentregacarga,
    statussolicitamdfe,
    datasolicitamdfe,
    statusprocessamento,
    datastatusprocessamento,
    statusnf,
    datastatusnf,
    statusdoc,
    datastatusdoc,
  });
  return response.data;
};

export const getviagemid = async (id) => {
  const response = await api.get(`viagemid/${id}`);
  return response.data;
};

//CARD TOTAL CARGAS
export const getTotalCarga = async () => {
  const response = await api.get(`/CardCargasConcluidas`);
  return response.data;
};
//CARD ENTREGAS REALIZADAS
export const getCargasEntregues = async () => {
  const response = await api.get(`/CardEntregasrealizadas`);
  return response.data;
};
//CARD ENTREGAS PENDENTES
export const getEntregasPendentes = async () => {
  const response = await api.get(`/CardEntregaspendentes`);
  return response.data;
};

//CARD ENTREGAS PENDENTES HORTI
export const getHortiPendentes = async () => {
  const response = await api.get(`/CardEntregaspendentesHorti`);
  return response.data;
};

//CARD ENTREGAS PENDENTES FRIOS
export const getFriosPendentes = async () => {
  const response = await api.get(`/CardEntregaspendentesfrios`);
  return response.data;
};

//CARD ENTREGAS PENDENTES FRIOS
export const getPanificacaoSeca = async () => {
  const response = await api.get(`/CardPanificacaoSecaPendente`);
  return response.data;
};
//CARD ENTREGAS PENDENTES FRIOS
export const getPanificacaoFrios = async () => {
  const response = await api.get(`/CardPanificacaoFriosPendente`);
  return response.data;
};

//CARD ENTREGAS PENDENTES NORMAL
export const getNormalPendentes = async () => {
  const response = await api.get(`/CardEntregaspendentesNormal`);
  return response.data;
};

//CARD GRAFICO 2
export const getGrafico2 = async () => {
  const response = await api.get(`/Graficopizzaentrega`);
  return response.data;
};

//CARD GRAFICO 3
export const getGrafico3 = async () => {
  const response = await api.get(`/Graficotodasentregasperfil`);
  return response.data;
};
//CARD GRAFICO 1
export const getGrafico1 = async () => {
  const response = await api.get(`/graficoentregasfinalizadas`);
  return response.data;
};
//CARD GRAFICO 1
export const getPainelProcessamento = async () => {
  const response = await api.get(`/painelprocessamento`);
  return response.data;
};

//##########################################TABELA MENSAGEM #########################################
export const getMensagem = async () => {
  const response = await api.get(`/tipomensagem`);
  return response.data;
};
//METODO DELETAR
export const deleteMensagem = async (id) => {
  await api.delete(`/tipomensagem/${id}`);
};
//METODO BUSCAR  POR  ID
export const getmensagemID = async (id) => {
  const response = await api.get(`tipomensagem/${id}`);
  return response.data;
};

//METODO UPDATE
export const putMensagem = async (id, { tipo, descricaomensagem }) => {
  const response = await api.put(`tipomensagem/${id}`, {
    tipo,
    descricaomensagem,
  });
  return response.data;
};

export const getGrupoMensagem = async () => {
  const response = await api.get(`/agrupamensagem`);
  return response.data;
};
export const deleteGrupoMensagem = async (id) => {
  await api.delete(`/agrupamensagem/${id}`);
};

// ROTAS OPERACOES
//METODO BUSCAR ITENS
export const getOperacoes = async () => {
  const response = await api.get(`/operacoes`);
  return response.data;
};
export const deleteOperacoes = async (id) => {
  await api.delete(`/operacoes/${id}`);
};

export const getoperacoesid = async (id) => {
  const response = await api.get(`operacoes/${id}`);
  return response.data;
};

export const putoperacoes = async (id, { tpoperacao }) => {
  const response = await api.put(`operacoes/${id}`, {
    tpoperacao,
  });
  return response.data;
};

//agrupa operacoes
export const deleteGrupooperacoes = async (id) => {
  await api.delete(`/agrupaoperacoesusers/${id}`);
};

//agrupa operacoes origens
export const deleteGrupooperacoesorigens = async (id) => {
  await api.delete(`/agrupaoperacoesorigens/${id}`);
};

//agrupa operacoes origens
export const deleteGrupooperacoesveiculos = async (id) => {
  await api.delete(`/agrupaoperacoesveiculos/${id}`);
};

//agrupa operacoes destino
export const deleteGrupooperacoesdestino = async (id) => {
  await api.delete(`/agrupaoperacoesdestino/${id}`);
};

//agrupa operacoes origens
export const deleteGrupooperacoesmotorista = async (id) => {
  await api.delete(`/agrupaoperacoesmotorista/${id}`);
};
