//FALTA FAZER//
//MELHORAR DIALOGO COM EXCLUSÃO.

//COMPONENSTE REACT
import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';

//COMPONENTES MATERIAL
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Link } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

//COMPONENTES INTERNOS
import {
  getMensagem,
  deleteMensagem,
} from '../../service/CRUD_Service';
import api from '../../api';
import { logout, getTipoUsuario } from '../../service/authService';

export default function Users() {
  const history = useHistory('');

  const [names, setNames] = useState([]); //set principal
  const [search, setSearch] = useState(''); //set de busca
  const [loading, setLoading] = useState(false); //set para antes de carregar a pagina aparecer loading
  const [page, setPage] = React.useState(0); //set para paginas
  const [rowsPerPage, setRowsPerPage] = React.useState(10); //set para numeros de paginas.
  const [nameError, setNameError] = useState(''); //set para refresc de pagina
  const [openExcluir, setOpenExcluir] = React.useState(false); //para botao  excluir apenas para visualzar o que esta escrito
  const [open, setOpen] = React.useState(false);

  //handle de paginas
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //handle de open
  const handleExcluirClose = () => {
    setOpenExcluir(false);
  };

  const handleExcluirOpen = () => {
    setOpenExcluir(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  //incluir veiculo
  useEffect(() => {
    setLoading(true);
    api
      .get('/tipomensagem')
      .then((res) => {
        //funcao para ordenar a lista por hora
        const itens = res.data.sort(function (a, b) {
          var c = new Date(a.tipo);
          var d = new Date(b.tipo);
          return c - d; //data mais antiga para mais nova
        });
        setNames(itens);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    reloadUsers();
  }, []);

  const reloadUsers = () => {
    setLoading(true);
    getMensagem()
      .then((res) => {
        const itens = res.sort(function (a, b) {
          var c = new Date(a.tipo);
          var d = new Date(b.tipo);
          return c - d; //data mais antiga para mais nova
        });
        setNames(itens);
        setLoading(false);
      })
      .catch((err) => setNameError(err));
  };

  //PASSA PARA BOTA EXCLUSAO
  const onDelete = (id) => {
    deleteMensagem(id)
      .then(() => {
        reloadUsers();
      })
      .catch((err) => setNameError(err));
  };

  // FAZ O LOADING ANTES DE CARREGAR OS DADOS
  if (loading) {
    return <p>Loading Mensagem.......</p>;
  }

  //EDITE VEICULO
  const onEdit = (id) => {
    history.push('/MesangemEdit', { id });
  };

  return (
    <div className='Users'>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position='static'
          color='default'
          style={{
            height: 50,
          }}
        >
          <Toolbar>
            <Typography variant='h6' noWrap>
              Lista de Mensagem
            </Typography>

            <ListItem
              button
              style={{
                width: 25,
                height: 25,
                justifyItems: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ListItemIcon
                onClick={logout}
                style={{
                  justifyItems: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ExitToAppIcon style={{ color: '#192d4d' }} />
              </ListItemIcon>
            </ListItem>
          </Toolbar>
        </AppBar>
      </Box>
      <br></br>

      <br></br>
      <br></br>
      <Paper
        style={{
          height: 50,
          marginLeft: 10,
          marginRight: 10,
          marginBottom: 10,
        }}
      >
        <InputBase
          style={{
            marginBlockEnd: 5,
            marginLeft: 4,
            color: 'default',
            height: 35,
            width: 200,
            //  marginTop: 5,
            backgroundColor: '#E4E7E8',
          }}
          type='text'
          className='form-control text-center input-text'
          //onChange={(ev) => setSearch(ev.target.value)}
          placeholder='Pesquisar mensagem'
          onChange={(e) => setSearch(e.target.value)}
        />

        <Button
          style={{
            height: 35,
            marginBlockEnd: 5,
            marginTop: 5,
            height: 35,
            marginLeft: 10,
            color: '#fff',
            paddingRight: 20,
          }}
          href='/Cadmensagem'
          variant='contained'
          color='primary'
          startIcon={<AddCircleIcon />}
        >
          Novo
        </Button>
      </Paper>
      <Paper style={{ marginLeft: 10, marginRight: 10 }}>
        <TableContainer>
          <br></br>
          <Table size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell
                  align='center'
                  style={{
                    backgroundColor: '#ffff',
                    color: '#333333',
                    fontWeight: 'bold',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  align='center'
                  style={{
                    backgroundColor: '#ffff',
                    color: '#333333',
                    fontWeight: 'bold',
                  }}
                >
                  Tipo Mensagem
                </TableCell>

                <TableCell
                  align='center'
                  style={{
                    backgroundColor: '#ffff',
                    color: '#333333',
                    fontWeight: 'bold',
                  }}
                >
                  Descricao
                </TableCell>

                <TableCell
                  align='center'
                  style={{
                    backgroundColor: '#ffff',
                    color: '#333333',
                    fontWeight: 'bold',
                  }}
                >
                  Opcoes
                </TableCell>
              </TableRow>
            </TableHead>

            {names
              .slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              .map((item, id) => (
                <TableBody key={item.id}>
                  <TableRow role='checkbox' key={item.id}>
                    <TableCell align='center'>{item.tipo} </TableCell>
                    <TableCell align='center'>
                      {(() => {
                        if (item.tipo == 1) {
                          return (
                            <Typography>Saida de Loja</Typography>
                          );
                        } else if (item.tipo == 2) {
                          return (
                            <Typography>
                              Entrega Finalizada
                            </Typography>
                          );
                        } else if (item.tipo == 3) {
                          return (
                            <Typography>Carga Gerada</Typography>
                          );
                        } else if (item.tipo == 4) {
                          return (
                            <Typography>Documento Gerado</Typography>
                          );
                        } else if (item.tipo == 5) {
                          return (
                            <Typography>Entrega Gerada</Typography>
                          );
                        } else if (item.tipo == 6) {
                          return (
                            <Typography>Tempo de Loja</Typography>
                          );
                        } else if (item.tipo == 7) {
                          return <Typography>Saida CD</Typography>;
                        }
                      })()}
                    </TableCell>
                    <TableCell align='center'>
                      {item.descricaomensagem}
                    </TableCell>

                    <TableCell align='center'>
                      <ButtonGroup>
                        {/*BOTAO EDITAR */}
                        <Tooltip
                          placement='right'
                          open={open}
                          onClose={handleClose}
                          onOpen={handleOpen}
                          TransitionComponent={Zoom}
                          title='Editar'
                        >
                          <IconButton
                            aria-label='Editar'
                            style={{ width: 25 }}
                            color='primary'
                          >
                            <EditIcon
                              fontSize='small'
                              onClick={() => onEdit(item.id)}
                            />
                          </IconButton>
                        </Tooltip>

                        {/*BOTAO EXCLUIR */}
                        <Tooltip
                          placement='left'
                          open={openExcluir}
                          onClose={handleExcluirClose}
                          onOpen={handleExcluirOpen}
                          TransitionComponent={Zoom}
                          title='Excluir'
                        >
                          <IconButton
                            aria-label='delete'
                            style={{ width: 25 }}
                            color='secondary'
                          >
                            <DeleteIcon
                              fontSize='small'
                              className='btexlcuir'
                              onClick={() => {
                                if (
                                  window.confirm(
                                    'Deseja confirmar exclusão'
                                  )
                                )
                                  onDelete(item.id);
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
          </Table>

          {/*PAGINAÇÃO DE PAGINAS**/}
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              labelRowsPerPage='Linhas por Paginas'
              component='div'
              count={names.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableContainer>
      </Paper>
    </div>
  );
}
