import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Button from "@material-ui/core/Button";
import moment from "moment";

import Badge from "@mui/material/Badge";
import MailIcon from "@material-ui/icons/Mail";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
//import momentDurationFormatSetup from 'moment-duration-format';
import "moment-duration-format";

import PrintIcon from "@material-ui/icons/Print";

import Timelinekoch from "./Timelinekoch";

import api from "../api";
import { EditCarga } from "../service/CRUD_Service";

export default function ListadeCargas() {
  const [cargas, setCargas] = useState([]); //recebe do banco de dados
  const [ent, setEnt] = useState({});
  const classes = useRowStyles();

  //busca dados apic
  useEffect(() => {
    api
      .get("painelprocessamentoautomatico")
      .then((res) => {
        let carg = res.data;
        //funcao para ordenar a lista por hora
        const itens = carg.sort(function (a, b) {
          var c = new Date(a.datasolicitamdfe).getTime();
          var d = new Date(b.datasolicitamdfe).getTime();
          return c - d; //data mais antiga para mais nova
        });
        setCargas(itens);
      })
      .catch(() => alert("erro ao carregar"));
  }, []);

  //FORMATAR DATA
  const formatDate = () => {
    var date = new Date();
    return date;
  };

  //ENVIA DADOS PARA A COLUNA STATUS VISUALIZADO (PENDENTE)
  const handleStatusvisualizadovisual = (row) => {
    let status = "Visualizado";
    let data = formatDate();
    let id = row.id;
    ent.statusprocessamento = status;
    ent.datastatusprocessamento = data;
    EditCarga(id, ent);
    alert("Atencao !!!, Visualizado");
    //   window.location.reload();
  };

  //ENVIA DADOS PARA A COLUNA STATUS VISUALIZADO (VISUALIZADO)
  const handleStatusvisualizadopendente = (row) => {
    let status = "Pendente";
    let data = formatDate();
    let id = row.id;
    ent.statusprocessamento = status;
    ent.datastatusprocessamento = data;
    EditCarga(id, ent);
    alert("Atencao !!!, Pendente");
    // window.location.reload();
  };

  //ENVIA DADOS PARA A COLUNA STATUS NF (SEM NF)
  const handleStatusSemNF = (row) => {
    let status = "Com NF";
    let data = formatDate();
    let id = row.id;
    ent.statusnf = status;
    ent.datastatusnf = data;
    EditCarga(id, ent);
    alert("Atencao !!!, Com NF");
  };
  //ENVIA DADOS PARA A COLUNA STATUS NF (COM NF)
  const handleStatusComNF = (row) => {
    let status = "Sem NF";
    let data = formatDate();
    let id = row.id;
    ent.statusnf = status;
    ent.datastatusnf = data;
    EditCarga(id, ent);
    alert("Atencao !!!, NF Pendente");
    //window.location.reload();
  };

  //ENVIA DADOS PARA A COLUNA STATUS  (SEM DOCUMENTO)
  const handleStatussemDoc = (row) => {
    let status = "Enviado";
    let data = formatDate();
    let id = row.id;
    ent.statusdoc = status;
    ent.datastatusdoc = data;
    EditCarga(id, ent);
    alert("Atencao !!!, Doc Pendente");
    //window.location.reload();
  };
  //ENVIA DADOS PARA A COLUNA STATUS  (COM DOCUMENTO))
  const handleStatusComDoc = (row) => {
    let status = "Pendente";
    let data = formatDate();
    let id = row.id;
    ent.statusdoc = status;
    ent.datastatusdoc = data;
    EditCarga(id, ent);
    alert("Atencao !!!, Doc Enviado");
    // window.location.reload();
  };
  const handleMensagem = (row) => {
    alert("Atencao !!!, Status Sem NF pendente, nao pode finalizar");
  };

  function FiltroCargaPDF(id) {
    let filtra = cargas.filter((x) => x.id == id);

    function PDFdadosNotas() {
      let htmlString =
        "<table><thead><tr><th>Numero Nota</th><th>Data Emissao</th><th>Loja</th></tr></thead><tbody>";

      // Segundo map
      filtra.forEach((i) => {
        i.cviagens.forEach((j) => {
          j?.fk_notas.forEach((nota) => {
            htmlString += `<tr style='height: 10px;' >`;
            htmlString += `<td style='font-size: 12px' >${nota.nr_nota}</td>`; // Adicione o estilo diretamente aqui
            htmlString += `<td style='font-size: 12px'>${new Date(
              nota.dt_emissao
            ).toLocaleString()}</td>`;
            htmlString += `<td style='font-size: 12px' >${j.fkdestino.razao}</td>`; // Adicione o estilo diretamente aqui
            htmlString += "</tr>";
          });
        });
      });
      htmlString += "</tbody></table>";
      return htmlString;
    }

    function PDFresumoDados() {
      let htmlString =
        "<table><thead><tr><th>Lojas</th><th>Perfil</th><th>Qtd Nota</th></tr></thead><tbody>";
      let QtdEntrega = [];

      let totalnf = [];

      // total de entrega
      filtra.forEach((i) => {
        i.cviagens.forEach((j) => {
          QtdEntrega.push(j.fkdestino.id);
        });
      });

      // lojas
      filtra.forEach((i) => {
        i.cviagens.forEach((j) => {
          htmlString += `<tr style='height: 10px;' >`;
          htmlString += `<td style='font-size: 12px' >Lojas ${j.fkdestino.lojas}</td>`;
          let QtdNotas = [];
          j.fk_notas.forEach((nota) => {
            QtdNotas?.push(nota.nr_nota);
            totalnf?.push(nota.nr_nota);
          });
          htmlString += `<td style='font-size: 12px' >${j.perfil}</td>`;
          htmlString += `<td style='font-size: 12px' >${QtdNotas.length}</td>`;
        });
      });

      htmlString += "</tr>";

      htmlString += "</tbody></table>";
      htmlString += `<p style='font-size: 14px' ><strong>Total Entrega :</strong> ${QtdEntrega.length}</p>`;
      htmlString += `<p style='font-size: 14px' ><strong>Total Nota :</strong> ${totalnf.length}</p>`;

      return htmlString;
    }

    function PDFdadosMotorista() {
      let htmlString = "<tr>";

      // Segundo map
      filtra.map((valor) => {
        htmlString += "<tr>";
        htmlString += `<td ><strong>Codigo:&nbsp </strong>${valor.fkmotorista.codigo}</td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
        htmlString += `<td ><strong>Motorista:&nbsp </strong>${valor.fkmotorista.nome}</td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
        htmlString += `<td ><strong>Tipo:&nbsp </strong>${valor.fkmotorista.tipo}</td > `;
        htmlString += "</tr>";
      });

      return htmlString;
    }

    function PDFdadosVeiculo() {
      let htmlString = "<tr>";

      // Segundo map
      filtra.map((valor) => {
        htmlString += "<tr>";
        htmlString += `<td ><strong>Veiculo Trator:&nbsp</strong>${valor.fkveiculotrator.placa}</td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
        htmlString += `<td ><strong>Unidade de Carga:&nbsp</strong>${valor.fkveiculo.nfrota}</td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;

        htmlString += `<td ><strong>Placa:&nbsp </strong>${valor.fkveiculo.placa}</td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;`;
        htmlString += `<td ><strong>Tipo:&nbsp </strong>${valor.fkveiculo.tipoveiculo}</td > `;
        htmlString += "</tr>";
      });

      return htmlString;
    }

    const DadosMotorista = PDFdadosMotorista();
    const DadosVeiculo = PDFdadosVeiculo();
    const DadosNotas = PDFdadosNotas();
    const ResumoDados = PDFresumoDados();

    const idcarga = filtra.map((x) => x.ncarga);

    const win = window.open("", "", "width=800,height=600");
    win.document.write(`
      <html>
      <head>
        <title>Cargas Koch</title>
        <style>
          table {
            border-collapse: collapse;
            width: 100%;
          }
          
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
          }
          
          th {
            background-color: #dddddd;
          }
          h2 {
            background-color: #194D33;
          }
          .dadosMotorista {
            margin-bottom: 8px;
            
          }
          .dadosVeiculo {
            margin-bottom: 55px;
            
          }
          .dadosNotas {
           
            
          }
          .cabecalho{
            display: flex;
            justify-content: space-between;
          
          }
          
        </style>
      </head>
      <body>
      <div class="cabecalho">

      <h1>Cargas Koch</h1>
      <h1>Nº ${idcarga}</h1>
      </div>
     
      <div  class="dadosMotorista">
      ${DadosMotorista}
      </div>
     
      <div  class="dadosVeiculo">
      ${DadosVeiculo}
      </div>

      <div  class="dadosVeiculo">
      ${ResumoDados}
      </div>
     
    
      <div  class="dadosNotas">
      ${DadosNotas}
      </div>
      </body>
      </html>
    `);

    win.document.close();
    win.print();

    return filtra;
  }

  //EXIBE DADOS DA TABELA
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [opennt, setOpennt] = useState(false);
    const [openedDeliveryId, setOpenedDeliveryId] = useState(null);
    const classes = useRowStyles();

    const [opencomentario, setOpencomentario] = React.useState(false);

    const handleClickOpen = () => {
      setOpencomentario(true);
    };

    const handleClose = () => {
      setOpencomentario(false);
    };

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            className={classes.txtOperacaotabela}
          >
            {row.id}
          </TableCell>

          <TableCell component="th" scope="row">
            {row.fkmotorista.codigo} - {row.fkmotorista.nome}
          </TableCell>
          {/* {row.fkmotorista.tipo} */}
          <TableCell align="center">{row.fkmotorista.tipo}</TableCell>
          <TableCell align="center">{row.fkveiculotrator.placa}</TableCell>
          <TableCell align="center">{row.fkveiculo.placa}</TableCell>
          <TableCell align="center">{row.fkveiculo.nfrota}</TableCell>
          {(() => {
            // let origem = row.cviagens.fkorigem.descricao;
            let origem = [
              ...new Set(row.cviagens.map((x) => x.fkorigem.descricao)),
            ];

            return (
              <TableCell
                component="th"
                scope="row"
                className={classes.txtOperacaotabela}
              >
                {origem}
              </TableCell>
            );
          })()}
          <TableCell align="center">
            <Typography
              style={{
                fontFamily: "inherit",
                fontWeight: "bold",
                color: "#40bf80",
              }}
            >
              {row.ncarga}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Button className={classes.icon} onClick={handleClickOpen}>
              <Badge badgeContent={1} color="success">
                <MailIcon color="action" />
              </Badge>
            </Button>
            <Dialog
              maxWidth="lg"
              open={opencomentario}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Time line da carga"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Timelinekoch />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Fechar</Button>
              </DialogActions>
            </Dialog>
          </TableCell>

          <TableCell align="center">
            <Button
              onClick={() => {
                FiltroCargaPDF(row.id);
              }}
            >
              <PrintIcon color="primary" />
            </Button>
          </TableCell>

          {/*STATUS DA CARGA ESTIVER VISUALIZADO A COR FICA AZUL,CASO CONTRA ROSA */}
          <TableCell align="center">
            {row.statusprocessamento == "Visualizado" ? (
              <Button
                onClick={() => handleStatusvisualizadopendente(row)}
                variant="outlined"
                style={{ fontSize: 12 }}
              >
                {row.statusprocessamento}
              </Button>
            ) : (
              <Button
                onClick={() => handleStatusvisualizadovisual(row)}
                variant="outlined"
                color="secondary"
                style={{ fontSize: 12 }}
              >
                {row.statusprocessamento}
              </Button>
            )}
          </TableCell>

          {/*STATUS DA NF, SE ESTIVER COM NF A COR FICA AZUL,CASO CONTRA ROSA */}
          <TableCell align="center">
            {row.statusnf == "Com NF" ? (
              <Button
                onClick={() => handleStatusComNF(row)}
                variant="outlined"
                color="primary"
                style={{ fontSize: 12 }}
              >
                {row.statusnf}
              </Button>
            ) : (
              <Button
                onClick={() => handleStatusSemNF(row)}
                variant="outlined"
                color="secondary"
                style={{ fontSize: 12 }}
              >
                {row.statusnf}
              </Button>
            )}
          </TableCell>

          {/*STATUS DO DOCUMENTO, SE O DOCUMENTO PENDENTE A COR FICA AZUL,CASO CONTRA ROSA */}
          <TableCell align="center">
            {(() => {
              if (row.statusnf === "Sem NF") {
                return (
                  <Button
                    onClick={() => handleMensagem(row)}
                    variant="outlined"
                    color="secondary"
                    style={{ fontSize: 12 }}
                  >
                    {row.statusdoc}
                  </Button>
                );
              } else if (row.statusdoc == "Enviado") {
                return (
                  <Button
                    onClick={() => handleStatusComDoc(row)}
                    variant="outlined"
                    color="primary"
                    style={{ fontSize: 12 }}
                  >
                    {row.statusdoc}
                  </Button>
                );
              } else {
                return (
                  <Button
                    onClick={() => handleStatussemDoc(row)}
                    variant="outlined"
                    color="secondary"
                    style={{ fontSize: 12 }}
                  >
                    {row.statusdoc}
                  </Button>
                );
              }
            })()}
          </TableCell>
        </TableRow>

        {/*EXIBI DETALHES DA CARGA */}
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Detalhes
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Lojas</TableCell>
                      <TableCell>Perfil</TableCell>
                      <TableCell align="center">Qtd P</TableCell>
                      <TableCell align="center">Total Nota</TableCell>
                      <TableCell align="center">Cidade</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* segundo map */}

                    {row.cviagens.map((valor) => (
                      <TableRow>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              setOpennt(!opennt);
                              setOpenedDeliveryId(valor.id);
                            }}
                          >
                            {opennt ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {valor.fkdestino.lojas}
                        </TableCell>
                        <TableCell> {valor.perfil}</TableCell>
                        <TableCell align="center">{valor.qtdpallet}</TableCell>

                        <TableCell align="center">
                          <Typography>{valor.fk_notas.length}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          {valor.fkdestino.municipio}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>

        {/*EXIBI DETALHES DA NOTAS */}
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={opennt} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Notas
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Numero Nota</TableCell>
                      <TableCell>Data Emissao</TableCell>
                      <TableCell>Loja</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* segundo map */}

                    {row.cviagens.map((valor) =>
                      valor?.fk_notas.map((nota) => {
                        let identrega = openedDeliveryId;

                        if (nota.fk_entregas == identrega) {
                          return (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {nota.nr_nota}
                              </TableCell>
                              <TableCell>
                                {(() => {
                                  const format1 = "DD/MM/YYYY HH:mm:ss";
                                  let horseteste = new Date(nota.dt_emissao);

                                  let formatarentradacd = moment
                                    .utc(horseteste)
                                    .format(format1);

                                  return formatarentradacd;
                                })()}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {valor.fkdestino.lojas}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell />
            <TableCell className={classes.txtheard}>ID Carga</TableCell>
            <TableCell className={classes.txtheard}>Motoristas</TableCell>
            <TableCell align="center" className={classes.txtheard}>
              Perfil Mt
            </TableCell>
            <TableCell align="center" className={classes.txtheard}>
              Placa Cavalo
            </TableCell>
            <TableCell align="center" className={classes.txtheard}>
              Placa Carreta
            </TableCell>
            <TableCell align="center" className={classes.txtheard}>
              SR
            </TableCell>
            <TableCell align="center" className={classes.txtheard}>
              Operacao
            </TableCell>
            <TableCell align="center" className={classes.txtheard}>
              Numero Carga
            </TableCell>
            <TableCell align="center" className={classes.txtheard}>
              Comentario
            </TableCell>
            <TableCell align="center" className={classes.txtheard}>
              Opcao
            </TableCell>
            <TableCell align="center" className={classes.txtheard}>
              Status Carga
            </TableCell>
            <TableCell align="center" className={classes.txtheard}>
              Status NF
            </TableCell>
            <TableCell align="center" className={classes.txtheard}>
              Status Doc{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cargas.map((row) => (
            <Row row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  head: {
    backgroundColor: "#FFFFFF",
  },
  txtheard: {
    fontWeight: "bold",
  },
  txtOperacaotabela: {
    fontSize: 16,
    color: "#bf4040",
    fontFamily: "inherit",
    fontWeight: "bold",
  },
});
