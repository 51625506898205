import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Box from "@mui/material/Box";

import VisibilityIcon from "@material-ui/icons/Visibility";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import api from "../api";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    borderRadius: 8,
    borderColor: "red",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    marginTop: -9,
    //  marginLeft: 40,
    color: "#ffff",

    alignItems: "center",
  },
  texto: {
    fontSize: 12,
    marginTop: -10,
    marginLeft: 48,
    color: "#ffff ",
  },
  pos: {
    marginBottom: 12,
  },

  dividir: {
    marginTop: -15,
    border: 10,
    backgroundColor: "#ffff",
  },
  qtd: {
    color: "#F5F5F5",
    fontSize: 40,
    fontWeight: "bold",
    marginTop: -5,
    //marginLeft: 42,
  },
  icon: {
    fontSize: 20,
  },
});

export default function CardVeiculoLojas({ datafiltra, operacoes }) {
  const classes = useStyles();
  const [carreta, setCarreta] = useState(1);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //incluir veiculo
  useEffect(() => {
    api
      .get("/dashFrotaveiculosLojaControllers")
      .then((res) => {
        let carg = res.data;
        setCarreta(carg);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/dashFrotaveiculosLojaControllers")
        .then((res) => {
          let carg = res.data;

          setCarreta(carg);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  function FiltraPlacas() {
    /*FLUXO DA FUNCAO
    VERIFICA OS VEICULOS QUE ESTAO NO RAIO DO LOCAL DE COLETA

    */
    if (carreta !== 1) {
      let Op = Number(operacoes);
      let vlojas = carreta;

      const filteredViagens = vlojas?.filter((v) => {
        // Obtendo todos os IDs de operações associadas à origem da viagem
        const origemOperacoesIds = v.fkentregas?.fkorigem?.Cad_operacoes?.map(
          (o) => o.id
        );

        // Verificando se algum dos IDs de operações da origem está presente nos IDs de operações do usuário
        return origemOperacoesIds?.some((id) => id === Op);
      });
      return filteredViagens?.length;
    } else {
      console.log("teste");
    }
  }

  const Setfuncao = FiltraPlacas();

  return (
    <Card
      style={{
        background: "#191E4D",
        justifyItems: "flex-start",
        justifyContent: "space-around",
        border: " 2px solid  #F4F6F9",
        borderRadius: 11,
        boxShadow: "1px 10px 10px #595959",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button className={classes.icon} onClick={handleClickOpen}>
            <VisibilityIcon className={classes.icon} />
          </Button>
          <Typography className={classes.qtd}>
            {Setfuncao == 0 ? 0 : Setfuncao}
          </Typography>
        </Box>
      </CardContent>
      <Divider className={classes.dividir} />
      <CardActions style={{ background: "#191E4D" }}>
        <Typography className={classes.title}>Veiculos Lojas</Typography>
        <Dialog
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Veiculos em Loja"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Subira na proxima atualizacao
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fechar</Button>
          </DialogActions>
        </Dialog>
      </CardActions>
    </Card>
  );
}
