import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@mui/material/Box";

import VisibilityIcon from "@material-ui/icons/Visibility";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import api from "../api";
import { ApiTracerVeiculos } from "../service/authtracer";
import axios from "axios";
import "../style/style_pag.css";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    borderRadius: 8,
    borderColor: "red",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    marginTop: -9,
    //  marginLeft: 40,
    color: "#ffff",

    alignItems: "center",
  },
  texto: {
    fontSize: 12,
    marginTop: -10,
    marginLeft: 48,
    color: "#ffff ",
  },
  pos: {
    marginBottom: 12,
  },

  dividir: {
    marginTop: -15,
    border: 10,
    backgroundColor: "#ffff",
  },
  qtd: {
    color: "#F5F5F5",
    fontSize: 40,
    fontWeight: "bold",
    marginTop: -5,
    //marginLeft: 42,
  },
  icon: {
    fontSize: 20,
  },
});

export default function VeiculosADefinir({ datafiltra, operacoes }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [dados, setDados] = useState(1);
  const [veiculos, setVeiculos] = useState();
  const [origem, setOrigem] = useState();

  function geolocalizacao(lat1, lon1, lat2, lon2) {
    // distance between latitudes
    // and longitudes
    let dLat = ((lat2 - lat1) * Math.PI) / 180.0;
    let dLon = ((lon2 - lon1) * Math.PI) / 180.0;

    // converte em radiano
    lat1 = (lat1 * Math.PI) / 180.0;
    lat2 = (lat2 * Math.PI) / 180.0;

    // aplica a formula
    let a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    let rad = 6371000; //circoferencia aproximada da terra, se querer passar para km cortar tres 0.
    let c = 2 * Math.asin(Math.sqrt(a));
    return rad * c;
  }

  let latcd = -27.224884; //ponto fixo
  let loncd = -48.621497; //ponto fixo
  let cerca = 420.67600239642678; // area do cd

  //incluir veiculo
  useEffect(() => {
    axios
      .get(ApiTracerVeiculos)
      .then((res) => {
        let carg = res.data[0].items;

        //let distancia = geolocalizacao(latcd, loncd, lat2, lon2)
        //let teste = data.map((item) => 'Placa:'+item.name +  ' longitude:' + item.lng  +' latitude:' + item.lat )
        let dados = carg.map((item) => [
          item.name,
          item.lat,
          item.lng,
          item.temperature,
        ]);

        setVeiculos(dados);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(ApiTracerVeiculos)
        .then((res) => {
          let carg = res.data[0].items;

          //let distancia = geolocalizacao(latcd, loncd, lat2, lon2)
          //let teste = data.map((item) => 'Placa:'+item.name +  ' longitude:' + item.lng  +' latitude:' + item.lat )
          let dados = carg.map((item) => [
            item.name,
            item.lat,
            item.lng,
            item.temperature,
          ]);

          setVeiculos(dados);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/dashfrotaveiculocdcarregado")
        .then((res) => {
          let carg = res.data;
          let filterCarga = carg.filter((x) => x.cviagens.length !== 0);
          //funcao para ordenar a lista por hora
          const itens = filterCarga.sort(function (a, b) {
            var c = new Date(a.createdAt).getTime();
            var d = new Date(b.createdAt).getTime();
            return c - d; //data mais antiga para mais nova
          });

          setDados(itens);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //incluir veiculo
  useEffect(() => {
    api
      .get("/dashfrotaveiculocdcarregado")
      .then((res) => {
        let carg = res.data;
        let filterCarga = carg.filter((x) => x.cviagens.length !== 0);
        //funcao para ordenar a lista por hora
        const itens = filterCarga.sort(function (a, b) {
          var c = new Date(a.createdAt).getTime();
          var d = new Date(b.createdAt).getTime();
          return c - d; //data mais antiga para mais nova
        });

        setDados(itens);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //incluir origem
  useEffect(() => {
    api
      .get("/origem")
      .then((res) => {
        let carg = res.data;

        let filterCerca = carg.filter((x) => x.cerca !== null);

        let pegaid = filterCerca.map(function (x) {
          return {
            id: x.id,
            lat: x.latitude,
            long: x.longitude,
            cerca: x.cerca,
          };
        });

        setOrigem(pegaid);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function FiltraPlacas() {
    /*FLUXO DA FUNCAO
    VERIFICA OS VEICULOS QUE ESTAO NO RAIO DO LOCAL DE COLETA

    */
    if (dados !== 1) {
      let Op = Number(operacoes);
      let veiculotracer = veiculos;
      let origemid = origem;
      let cargas = dados;
      let placasdistancia = [];
      let novoArray = [];

      // dentro do for tem que testar
      for (var i = 0; i < origemid?.length; i++) {
        for (var j = 0; j < veiculotracer?.length; j++) {
          if (
            geolocalizacao(
              veiculotracer[j][1],
              veiculotracer[j][2],
              origemid[i].lat,
              origemid[i].long
            ) <= origemid[i].cerca
          ) {
            placasdistancia.push({
              Origensid: origemid[i].id,
              placa: veiculotracer[j][0],
              temperatura: veiculotracer[j][3],
            });
          }
        }
      }

      let filtrarindefinido = cargas?.filter(
        (item) => item.idveiculotrator == 28
      );

      filtrarindefinido?.map((item) =>
        placasdistancia?.map((x) => {
          if (x.placa == item.fkveiculo.placa) {
            novoArray.push(Object.assign(item, x));
          }
        })
      );

      // Filtrando as viagens com base nos IDs de operações do usuário
      const filteredViagens = novoArray?.filter((v) => {
        // Obtendo todos os IDs de operações associadas à origem da viagem
        const origemOperacoesIds = v.cviagens?.flatMap((c) =>
          c.fkorigem.Cad_operacoes?.map((o) => o.id)
        );

        // Verificando se algum dos IDs de operações da origem está presente nos IDs de operações do usuário
        return origemOperacoesIds?.some((id) => id === Op);
      });

      return filteredViagens?.length;
    } else {
      console.log("teste");
    }
  }

  const Setfuncao = FiltraPlacas();

  return (
    <Card
      style={{
        background: "#191E4D",
        justifyItems: "flex-start",
        justifyContent: "space-around",
        border: " 2px solid  #F4F6F9",
        borderRadius: 11,
        boxShadow: "1px 10px 10px #595959",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {Setfuncao >= 20 ? (
            <Button className={classes.icon} onClick={handleClickOpen}>
              <VisibilityIcon className="qtdveiculoAdefinir" />
            </Button>
          ) : (
            <Button className={classes.icon} onClick={handleClickOpen}>
              <VisibilityIcon className={classes.icon} />
            </Button>
          )}

          <Typography className={classes.qtd}>
            {Setfuncao == 0 ? 0 : Setfuncao}
          </Typography>
        </Box>
      </CardContent>
      <Divider className={classes.dividir} />
      <CardActions style={{ background: "#191E4D" }}>
        <Typography className={classes.title}>Veiculos a Definir</Typography>
        <Dialog
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Veiculos Transito"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Subira na proxima atualizacao
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fechar</Button>
          </DialogActions>
        </Dialog>
      </CardActions>
    </Card>
  );
}
